export const checkoutSteps = [
  "checkout.step.address",
  "checkout.step.payment-and-delivery",
  "checkout.step.place-order",
] as const;

export const checkoutStepsNames = {
  STEP_ADDRESS: 0,
  STEP_PAYMENT_AND_DELIVERY: 1,
  STEP_PLACE_ORDER: 2,
} as const;
